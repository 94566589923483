/**
 * Routes for all available project pages
 */
export enum EnumRoutePaths {
  // Root page
  BASE = '/',
  CHECKING = '/order-checking',
  AI_SUGGESTIONS = '/ai',
  TOUR_BOOKING = '/tour-booking',
  VIP_TOURS = '/vip-tours',
  ABOUT = '/about',
  PRIVACY = '/privacy',
  CART = '/cart',
  SEARCH = '/search',
  RATES = '/rates',
  BLOG = '/blog',
  SINGLE_BLOG = '/blog/:id',
  REFERRER = '/referrer',
  CITY_TOURS = '/tours',
}
