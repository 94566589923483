import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IRootState } from 'src/types/store-types';
import './styles.scss';

const ImageCarousel: FC = () => {
  const { imagesArr } = useSelector((state: IRootState) => state.app);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if (imagesArr?.length) {
      setSelectedImage(imagesArr[0]);
    }
  }, [imagesArr]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: imagesArr.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: imagesArr.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: imagesArr.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // If imagesArr is falsy, show placeholder loaders
  if (!imagesArr?.length) {
    return (
      <div className="carousel-container">
        {/* Main Image Placeholder */}
        <div className="main-image placeholder">
          <div className="placeholder-loader" />
        </div>

        {/* Thumbnail Carousel Placeholder */}
        <div className="thumbnail-carousel">
          <Slider {...settings}>
            {[...Array(4)].map((_, index) => (
              <div key={index} className="thumbnail placeholder">
                <div className="placeholder-loader" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }

  return (
    <div className="carousel-container">
      {/* Main Image */}
      <div className="main-image">
        <img src={selectedImage} alt="Selected" />
      </div>

      {/* Thumbnail Carousel */}
      <div className="thumbnail-carousel">
        <Slider {...settings}>
          {imagesArr.map((image: string, index: number) => (
            <div
              key={index}
              className={`thumbnail ${selectedImage === image ? 'active' : ''}`}
              onClick={() => setSelectedImage(image)}
            >
              <img src={image} alt={`Thumbnail ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImageCarousel;
