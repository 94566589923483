import { createAction } from 'deox';
import {
  APP_GET_CITIES,
  APP_RESET_IMAGES,
  APP_SET_CITIES,
  APP_SET_IMAGES,
  APP_SET_LNG,
  APP_SET_LOCATION,
} from './types';

export const setImagesArr = createAction(APP_SET_IMAGES, (res) => (params) => res(params));
export const resetImagesArr = createAction(APP_RESET_IMAGES);

export const setLocation = createAction(APP_SET_LOCATION, (res) => (params) => res(params));
export const setLng = createAction(APP_SET_LNG, (res) => (params) => res(params));

export const getCities = createAction(APP_GET_CITIES, (res) => (params) => res(params));
export const setCities = createAction(APP_SET_CITIES, (res) => (params) => res(params));
