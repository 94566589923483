import { API_VERSION } from 'src/utils/constants';

import { makeQueryString } from 'src/utils/helpers';
import HttpClient from './http';

export const getToursReq = (payload: any): Promise<any> => {
  return HttpClient.get({
    path: `${API_VERSION}/tours/?${makeQueryString(payload)}&sortByRate=true`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};
export const getTourByIdReq = (id: string): Promise<any> => {
  return HttpClient.get({ path: `${API_VERSION}/tours/${id}`, baseURL: process.env.REACT_APP_REMOTE as string });
};

export const getToursByName = (query: Record<string, any>): Promise<any> => {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => params.append(`${key}[]`, item));
    } else if (value !== undefined && value !== null && value !== '') {
      params.append(key, value);
    }
  });

  const queryString = params.toString();

  return HttpClient.get({
    path: `${API_VERSION}/tours/search?${queryString}`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};
