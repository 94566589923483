import { FC } from 'react';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import './styles.scss';

type Props = {
  className: string;
};

const SocialIcons: FC<Props> = ({ className }) => {
  return (
    <div className={`social-container ${className}`}>
      <div className="icon-container link">
        <a
          aria-label="facebook"
          href="https://www.facebook.com/TouRest.online/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SvgIcon src="/images/icons/fbIcon.svg" color="grey" size="mdm" />
        </a>
      </div>
      <div className="icon-container link">
        <a href="https://vk.com/tourest_online" target="_blank" rel="noopener noreferrer" aria-label="vkontakte">
          <SvgIcon src="/images/icons/vk.svg" color="grey" size="mdm" />
        </a>
      </div>
      <div className="icon-container link">
        <a
          href="https://www.instagram.com/tourest_online/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="instagram"
        >
          <SvgIcon src="/images/icons/ig.svg" color="grey" size="mdm" />
        </a>
      </div>
    </div>
  );
};

export default SocialIcons;
