import { FC } from 'react';
import ImageCarousel from 'src/components/common/ImagesSlider';

const WithSliderLayout: FC<any> = ({ children }) => {
  return (
    <>
      <ImageCarousel />
      {children}
    </>
  );
};

export default WithSliderLayout;
