import { FC } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import store from './store';

import Routes from 'src/components/Routes';
import ErrorBoundaryComponent from 'src/hoc/ErrorBoundary';

import Analitics from './components/common/Analitics';
import './styles/index.scss';

import { PostHogProvider } from 'posthog-js/react';

const options: any = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const App: FC = () => {
  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as any} options={options}>
      <Provider store={store}>
        <ErrorBoundaryComponent>
          <Analitics />
          <div className="wrapper">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Routes />
          </div>
        </ErrorBoundaryComponent>
      </Provider>
    </PostHogProvider>
  );
};

export default App;
