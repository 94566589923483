// import { IAdvancedTableCols } from 'src/types/common-types/filters';

export type appState = {
  imagesArr: string[];
  location: string;
  lng: string;
  cities: string[];
  languages: string[];
};

export const APP_SET_IMAGES = 'app/APP_SET_IMAGES';
export const APP_RESET_IMAGES = 'app/APP_RESET_IMAGES';

export const APP_SET_LOCATION = 'app/APP_SET_LOCATION';
export const APP_SET_LNG = 'app/APP_SET_LNG';

export const APP_GET_CITIES = 'app/APP_GET_CITIES';
export const APP_SET_CITIES = 'app/APP_SET_CITIES';
