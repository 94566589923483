import { Action, combineReducers } from 'redux';
import { IRootState } from 'src/types/store-types';
import { appReducer } from './app/reducer';
import { orderReducer } from './order/reducer';
import { tourReducer } from './tours/reducer';

const projectReducer = combineReducers({
  app: appReducer,
  tours: tourReducer,
  orders: orderReducer,
});

const rootReducer = (state: any | undefined, action: Action): IRootState => {
  return projectReducer(state, action);
};

export default rootReducer;
